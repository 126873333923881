import React from "react"
import { Container } from "react-bootstrap"
import Buld from "../../../static/images/buld.svg"
import Cart from "../../../static/images/Cart.svg"
import CartTwo from "../../../static/images/Cart2.svg"
import Compass from "../../../static/images/Compass.svg"
import Incubators from "../../../static/images/Incubators.svg"
import Select from "../../../static/images/select.svg"
import Target from "../../../static/images/Target.svg"
import Cta from "../../components/cta"
import Footer from "../../components/footer"
import GridContainer from "../../components/gridContainer"
import GridItem from "../../components/gridItem"
import Layout from "../../components/layout"
import LogoCloud from "../../components/LogoClouds"
import NavBar from "../../components/navBar"
import Seo from "../../components/seo"
import SolutionNav from "../../components/SolutionNav"
import NewHero from "../../components/hero/newHero";

const Incubator = ({ location }) => {
  return (
    <Layout>
      <Seo title="Team Coaches" />
      <NavBar location={location} />
      <Container>
        <SolutionNav isActive={1} />
        <NewHero
          title="We Help Maximize Your Startup Incubator Growth"
          description="Team Coaches helps you scale your incubator by saving you valuable time and money."
          altText="Incubators"
          image={Incubators}
        />
        <LogoCloud />
        <GridContainer>
          <GridItem
            icon={Buld}
            width="66"
            height="66"
            title="Bring Your Idea"
            description="Cultivate ideas that change the world."
          />
          <GridItem
            icon={Select}
            width="61"
            height="61"
            title="Learn The Basics"
            description="Educate new innovators who wants to change the world."
          />
          <GridItem
            icon={Cart}
            width="58"
            height="58"
            title="Create Your MVP"
            description="Build an MVP with the right tools and goal settings."
          />
          <GridItem
            icon={Compass}
            width="58"
            height="58"
            title="Validate Your Product"
            description="Bring it to market and measure your progress and impact."
          />
          <GridItem
            icon={CartTwo}
            width="66"
            height="66"
            title="Go to Market"
            description="Help with the marketing of your idea, creating the plan you need."
          />
          <GridItem
            icon={Target}
            width="58"
            height="58"
            title="Goals"
            description="Objective and Key Results to measure your path to success."
          />
        </GridContainer>
        <Cta
          backgroundColor="back-blue"
          buttonColor="blue"
          title="Convert ideas into sustainable businesses."
          points={["Modules adapted to your needs."]}
          buttonText="Let's Talk"
        />
        <Footer />
      </Container>
    </Layout>
  )
}

export default Incubator
